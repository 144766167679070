import AnimationBlockSwap from "@components/animations/AnimationBlockSwap";
import AnimationImageSwap from "@components/animations/AnimationImageSwap";
import FloatingTicket from "@components/ui/floating-ticket/FloatingTicket";
import LinearContainer from "@components/ui/linear-container/linear-container";
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { featureSectionCopy } from "src/content/features";
import { useAnimationCheckpoint } from "src/hooks/use-animation-checkpoint";

const FeatureShow = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const imageArray = featureSectionCopy.images;
  const contentArray = featureSectionCopy.content;

  const sectionRef = useRef(null);
  const { checkpointValue } = useAnimationCheckpoint(sectionRef, imageArray, [
    "start start",
    "end end",
  ]);

  useEffect(() => {
    setCurrentImageIndex(checkpointValue);
  }, [checkpointValue]);

  return (
    <div
      className="relative flex justify-between max-lg:justify-center gap-8"
      style={{
        height: isDesktopOrLaptop
          ? `${100 * imageArray.length - 1}svh`
          : "auto",
      }}
      ref={sectionRef}
      dir="rtl"
    >
      {isDesktopOrLaptop ? (
        <FeatureTextDesktop data={contentArray[currentImageIndex]} />
      ) : (
        <FeatureTextMobile data={contentArray} />
      )}
      {isDesktopOrLaptop && (
        <div className="flex justify-end w-full">
          <LinearContainer className="top-24 !sticky h-[84svh] overflow-hidden aspect-[370/667]">
            <div className="-bottom-28 max-sm:-bottom-36 -left-20 max-sm:-left-24 z-10 absolute w-[701px] phone-holder scale-[.8] max-sm:scale-75">
              <div className="top-[175px] left-[116px] -z-[1] absolute bg-[#282d35] rounded-rounded w-[318.839px] h-[691px] overflow-hidden">
                <AnimationImageSwap>
                  <img
                    src={imageArray[currentImageIndex].src}
                    alt={imageArray[currentImageIndex].name}
                    className="bg-black mx-auto rounded-3xl w-full h-full object-cover"
                  />
                </AnimationImageSwap>
              </div>
              <img
                src="/images/features-section/phone-holder.webp"
                alt="Phone Holder"
                width="616"
                height="905"
                className="bottom-0 w-full h-[1030px] aspect-[1/2]"
              />
            </div>
            <div className="relative">
              <img
                src="/images/LinearBackground.webp"
                alt="Linear Background"
                className="w-full aspect-[409/818]"
                width={408}
                height={817}
              />
            </div>
          </LinearContainer>
        </div>
      )}
    </div>
  );
};

export default FeatureShow;

const FeatureTextDesktop = ({ data }: { data: any }) => {
  return (
    <div className="top-24 sticky py-4 w-full max-w-[40rem] h-[37.5rem] overflow-hidden">
      <div className="relative w-full h-full">
        <AnimationBlockSwap>
          {data.ticketText && (
            <FloatingTicket ticketContent={data.ticketText} />
          )}
          <h1 className="font-geDinarone text-grey-w0 text-h2 leading-[140%] tracking-wide">
            {data.title}
          </h1>
          <h4 className="font-medium font-sora text-grey-w1 text-md md:text-xl antialiased leading-10 tracking-wide">
            {data.sub}
          </h4>
        </AnimationBlockSwap>
      </div>
    </div>
  );
};

const FeatureTextMobile = ({ data }: { data: any }) => {
  return (
    <div className="flex flex-col items-center gap-8">
      <div className="flex flex-col gap-8 py-4 lg:h-[600px] overflow-hidden">
        <div className="flex flex-col gap-16">
          {data.map((element: any) => {
            return (
              <div className="flex flex-col items-start gap-4 md:gap-8 bg-[#151817] max-lg:mx-auto max-w-[640px] lg:h-screen max-lg:items-center max-lg:[&>*]:text-center">
                <h1 className="text-right font-geDinarone text-grey-w0 text-small-h3 md:text-h3 antialiased">
                  {element.title}
                </h1>
                <h4 className="text-right font-medium md:font-normal text-grey-w1 text:md md:text-h4 antialiased leading-8">
                  {element.sub}
                </h4>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
