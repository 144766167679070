import { AnimatePresence, motion } from "framer-motion";
import type { ReactNode } from "react";
import { v4 as uuidv4 } from "uuid";

const AnimationImageSwap = ({ children }: { children: ReactNode }) => {
  return (
    <AnimatePresence>
      <motion.div
        variants={imageSwapVarients}
        initial="initial"
        animate="animate"
        exit="exit"
        key={uuidv4()}
        className="absolute"
      >
        <div className="h-full w-full overflow-hidden rounded-3xl">
          {children}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default AnimationImageSwap;



const imageSwapVarients = {
    initial: {
      y: "100%",
      opacity: 0,
      clipPath: "circle(100% at 50% 177%)",
      transition: {
        duration: 0.5,
      },
    },
    animate: () => ({
      y: "0%",
      opacity: 1,
      clipPath: "circle(100% at 50% 77%)",
      transition: {
        duration: 0.5,
      },
    }),
    exit: {
      y: "0%",
      opacity: 0,
      clipPath: "circle(100% at 50% 77%)",
      transition: {
        duration: 0.5,
      },
    },
  };