export const featureSectionCopy = {
  content: [
    {
      title: "بش تتعلم من عباد تعمل فلي تحكي فيه",
      sub: (
        <>
          إلي يعلمو الكل عندهم خبرة في
          <span className="font-sora">Freelance</span> و دخلو منو برشا فلوس و
          مازالو يخدموا إلى حد اليوم، معناها بش يعطيوك نصائح تطبيقية و فعالة.
        </>
      ),
    },

    {
      title: (
        <>
          دعم <span className="font-sora">24/7</span> عبر واتساب
        </>
      ),
      sub: (
        <>
          بش نوفرولك رقم واتساب لي تبعثلو اذا كان عندك اي مشكل او استفسار،
          الفريق الكل متواجد على ذمتك بش يحلك مشاكلك.
        </>
      ),
    },
    {
      title: "إدفع مرة واحدة برك",
      sub: (
        <>
          إدفع مرة واحدة برك و تبقالك المنصة مدى الحياة، إحصل على كل الدورات مع
          إمكانية الوصول إليها في أي وقت.
        </>
      ),
    },
    {
      title: (
        <>
          <span className="font-sora">6</span> دورات بسوم وحدة برك
        </>
      ),
      sub: (
        <>
          تاخو 6 دورات بسوم وحدة هكاكا تتعلم برشا حاجات و يكون عندك الاختيار، و
          كيما حكينا مش الدورات برك فما برشا محتوى أخر و حصص شهرية زادة على
          المدى الطويل
        </>
      ),
    },
  ],
  images: [
    {
      src: "/images/features-section/user-icon.webp",
      name: "user icon",
    },

    {
      src: "/images/features-section/support-icon.webp",
      name: "support icon",
    },
    {
      src: "/images/features-section/security-icon.png",
      name: "security icon",
    },
    {
      src: "/images/features-section/time-icon.png",
      name: "time icon",
    },
  ],
};
