import { AnimatePresence, motion } from "framer-motion";
import type { ReactNode } from "react";
import { v4 as uuidv4 } from "uuid";
const AnimationBlockSwap = ({ children }: { children: ReactNode }) => {
  return (
    <AnimatePresence>
      <motion.div
        className="flex flex-col items-start gap-8 bg-[#151817] max-w-[640px] h-screen absolute top-0 right-0"
        key={uuidv4()}
        variants={BlockSwapVarients}
        initial="initial"
        animate="animate"
        exit={"exit"}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export default AnimationBlockSwap;

const BlockSwapVarients = {
  initial: {
    opacity: 0,
    y: "100%",
    scale: 0.9,
    z: 1200,
    transition: {
      duration: 0.5,
    },
  },
  animate: {
    opacity: 1,
    y: "0%",
    scale: 1,
    z: 1200,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
    y: "-15%",
    scale: 0.9,
    z: 1200,
    transition: {
      duration: 0.5,
    },
  },
};
